<template>
    <v-container class="fill-height">
        <v-row class="fill-height">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-icon @click="pushRouteToView('persons')" class="mr-4" style="cursor: pointer">
                            mdi-arrow-left
                        </v-icon>
                        <v-icon class="mr-3">mdi-account-group</v-icon>
                        Editar Cliente
                        <v-spacer></v-spacer>
                        <!--                            <v-btn color="success" :to="{ name: 'PersonCreate' }">-->
                        <!--                                <v-icon left>mdi-plus</v-icon>-->
                        <!--                                Adicionar-->
                        <!--                            </v-btn>-->
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col md="12" class="fill-height">
                <v-form ref="formPerson"
                        v-model="formIsValid"
                        v-on:submit.prevent="personSubmit(person.id)">
                    <v-card class="fill-height">
                        <v-card-text>
                            <v-row>
                                <v-col md="4" sm="12">
                                    <v-text-field
                                        v-model="person.name"
                                        outlined
                                        :rules="textRules"
                                        label="Nome"
                                    />
                                </v-col>
                                <v-col md="3" sm="12">
                                    <v-text-field
                                        v-model="person.cpf"
                                        outlined
                                        :rules="cpfRule"
                                        label="CPF"
                                        v-mask="['###.###.###-##']"
                                    />
                                </v-col>
                                <v-col md="3" sm="12">
                                    <v-text-field
                                        v-model="person.phone"
                                        outlined
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        :rules="telRules"
                                        label="Telefone"
                                    />
                                </v-col>
                                <v-col md="2" sm="12">
                                    <v-text-field
                                        v-model="person.birthdate"
                                        outlined
                                        :rules="dateRule"
                                        v-mask="['##/##/####']"
                                        label="Data de nascimento"
                                    />
                                </v-col>
                                <v-col md="12" class="text-right">
                                    <v-row>
                                        <v-col md="12">
                                            <v-btn
                                                type="submit"
                                                style="color: #fff; background-color: rgb(61, 17, 89)"
                                            >
                                                <v-icon left>
                                                    mdi-check
                                                </v-icon>
                                                Enviar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {mask} from "vue-the-mask";
import {PERSON_RESET_STATE} from "@/store/mutations/person.type";
import {PERSON_CREATE, PERSON_UPDATE, PERSON_GET} from "@/store/actions/person.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";


export default {
    name: 'PersonsEdit',
    directives: {mask},
    props: {
        id: {
            default: 0,
            type: Number,
            required: false
        }
    },

    components: {},
    async beforeRouteUpdate(to, from, next) {
        // Reset state if user goes from /editor/:id to /editor
        // The component is not recreated so we use to hook to reset the state.
        await store.commit(PERSON_RESET_STATE);
        return next();
    },
    async beforeRouteEnter(to, from, next) {
        await store.commit(PERSON_RESET_STATE);
        console.log(to.params.id)
        if (to.params.id !== undefined)
            await store.dispatch(PERSON_GET, to.params.id);

        return next();
    },
    data: () => ({

            formPerson: false,
            formIsValid: "",

            textRules: [
                v => !!v || "Este campo é obrigatório"
                //v => v > 0 || "Esta campo deve ter um valor maior que zero."
            ],
            telRules: [
                v => !!v || "Telefone é obrigatório",
                value => {
                    if (typeof value !== "undefined" && value !== null) {
                        if (value.length > 0) {
                            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
                            return pattern.test(value) || "Informe um telefone válido";
                        }
                    }
                    return false;
                }
            ],
            cpfRule: [
                // v => !!v || true,
                strCPF => {
                    if (!strCPF) return true;
                    strCPF = strCPF.replace(/\D/g, "");
                    let Soma;
                    let Resto;
                    Soma = 0;
                    if (strCPF == "00000000000") return "CPF Inválido";
                    let i = 0;
                    for (i = 1; i <= 9; i++)
                        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                    Resto = (Soma * 10) % 11;

                    if (Resto == 10 || Resto == 11) Resto = 0;
                    if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

                    Soma = 0;
                    for (i = 1; i <= 10; i++)
                        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                    Resto = (Soma * 10) % 11;

                    if (Resto == 10 || Resto == 11) Resto = 0;
                    if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
                    return true;
                }
            ],
            dateRule: [
                date => {
                    if (!date) return true;
                    const pattern = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
                    return pattern.test(date) || "Informe uma data válida."
                }
            ]
        }
    ),

    methods: {
        pushRouteToView(route) {
            this.$router.push({name: route});
        }
        ,
        personSubmit(id) {
            if (this.$refs.formPerson.validate()) {

                let action = id ? PERSON_UPDATE : PERSON_CREATE;
                store.commit(CHANGE_PROGRESS, true);
                // this.bloqueiaEnvio = true;
                this.$store
                    .dispatch(action)
                    .then(() => {
                        store.commit(CHANGE_PROGRESS, false);

                        this.$refs.formPerson.resetValidation();
                        store.commit(PERSON_RESET_STATE);
                        this.snackbar.snackbar = true;
                        this.snackbar.color = "success";
                        this.snackbar.text = "Salvo com sucesso";

                        this.$router.push({name: "persons"})

                    })
                    .catch(({response}) => {
                        store.commit(CHANGE_PROGRESS, false);

                        this.snackbar.snackbar = true;
                        this.snackbar.color = "error";
                        this.snackbar.text = "Erro ao salvar Cliente.";
                        // console.log(response.data);
                        if (typeof response.data.message !== "undefined") {
                            this.snackbar.text = response.data.message;
                        }
                    });
            } else {
                this.snackbar.snackbar = true;
                this.snackbar.color = "error";
                this.snackbar.text = "Preencha o formulário corretamente";
            }
        }
    }
    ,
    computed: {
        ...
            mapGetters(["person", "snackbar", "inProgress"])
    }

}
</script>